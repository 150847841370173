import React from 'react';
import { makeStyles, Typography, Grid, Button, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
//import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
//import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
//import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    lineHeight: 1.2,
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0.5em 0 0',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  mobileButtonJoin: {
    padding: '0.8em 25px',
    margin: 0,
    borderRadius: 40,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  //const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      //process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Blir med i test...
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5">Kamera og lydoppsett</Typography>

      <Typography className={classes.gutterBottom}>
        Kontroller at du kan se bilde av deg selv og at mikrofon er tilkoblet
        <br />
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={10} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
        </Grid>
        <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
          <div className={classes.joinButtons}>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
            <Button
              className={classes.mobileButtonJoin}
              variant="contained"
              color="primary"
              data-cy-join-now
              onClick={handleJoin}
              disabled={disableButtons}
            >
              Kom i gang
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
