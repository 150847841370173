// src/App.tsx

import React from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreensNew';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';
import SessionTimeoutDialog from './components/SessionTimeoutDialog/SessionTimeoutDialog';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import useSessionManagement from './hooks/useSessionManagement/useSessionManagement';
import { useAppState } from './state';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`,
  background: theme.brand,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`,
  },
}));

export default function App() {
  const { user } = useAppState();
  const roomState = useRoomState();
  const height = useHeight();
  const { sessionExtended, dialogOpen, handleDismissDialog, handleExtendSession } = useSessionManagement();

  if (!user) {
    return null; // Or a loading spinner, or redirect to login
  }

  return (
    <div>
      <Container style={{ height }}>
        {roomState === 'disconnected' ? (
          <PreJoinScreens />
        ) : (
          <Main>
            <ReconnectingNotification />
            <RecordingNotifications />
            <MobileTopMenuBar />
            <Room />
            <MenuBar />
          </Main>
        )}
      </Container>
      <SessionTimeoutDialog
        open={dialogOpen}
        onClose={handleDismissDialog}
        onExtend={handleExtendSession}
        sessionExtended={sessionExtended}
      />
    </div>
  );
}
