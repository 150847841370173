import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback } from 'react';

import { DefaultWizardStepProps, Step, Steps, useWizardContext, WizardProvider } from 'react-sweet-wizard';

import { isMobile } from '../../utils';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import ToggleRecordButton from '../Buttons/ToggleRecordButton/ToggleRecordButton';
import useIsRecording from '../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useAppState } from '../../state';

//import useStepsState from '../../hooks/useStepsState/useStepsState';
import useTestCaseData from '../../hooks/useTestCaseData/useTestCaseData';
import useHeight from '../../hooks/useHeight/useHeight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
    },
    right: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    left: {
      width: '50%',
      display: 'flex',
      marginRight: '30px',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    nonFlex: {
      display: 'block',
    },
    responsiveIframe: {
      width: '100%',
      height: '80%',
      paddingBottom: '0',
      position: 'relative',
    },
    responsiveImage: {
      width: '80%',
      height: '80%',
      position: 'relative',
    },
    nav: {
      //marginTop: '20px',
    },
    flex: {
      display: 'flex',
    },
    link: {
      color: 'white',
    },
    linkText: {
      //fontSize: '1.9em',
      fontSize: 'calc(1.5vh + 1em)',
      marginBottom: '30px',
    },
    task: {
      maxWidth: '950px',
      '@media (min-width:600px)': {
        fontSize: 'calc(1.5vh + 0.8em)',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 'calc(1.5vh + 1em)',
      },
      whiteSpace: 'pre-line',
    },
    introText: {
      maxWidth: '750px',
      //fontSize: '1.9em',
      fontSize: 'calc(1.5vh + 1em)',
    },
    instruction: {
      fontSize: '1.3em',
    },
    instructionWithMarginBottom: {
      fontSize: '1.3em',
      marginBottom: '30px',
      opacity: 0.8,
    },
    button: {
      borderRadius: 25,
      background: theme.brandWhite,
      color: theme.brand,
      marginRight: 20,
      '&:hover': {
        background: theme.brandWhiteDark,
        color: theme.brand,
      },
      '&[disabled]': {
        color: '#bbb',
        display: 'none',
        '& svg *': {
          fill: '#bbb',
        },
      },
    },
  })
);

//const Progress = () => {
//  const { activeStepIndex, steps } = useWizardContext<DefaultWizardStepProps>();

//  return (
//    <div>
//      {activeStepIndex + 1} av {steps.length}
//    </div>
//  );
//};

const Navigation = () => {
  const classes = useStyles();
  const { onNext, onPrevious, isFirstStep, isLastStep } = useWizardContext<DefaultWizardStepProps>();
  //const { onNext, onPrevious, goTo, isFirstStep, isLastStep } = useWizardContext<DefaultWizardStepProps>();
  //goTo(5);

  return (
    <div className={classes.nav}>
      <Button onClick={onPrevious} className={classes.button} disabled={isFirstStep}>
        Forrige
      </Button>

      <Button
        className={classes.button}
        onClick={useCallback(() => {
          onNext(() => console.log('Calling `onNext` method'));
        }, [onNext])} // REMOVED goTo, onNext, activeStepIndex here...
        disabled={isLastStep}
      >
        Neste
      </Button>
    </div>
  );
};

interface data {
  text?: string;
  text2?: string;
  link?: string;
  totalTasks?: number;
  currentTask?: number;
  type?: string;
  asset?: any;
}

const DynamicPage = ({ text, link, totalTasks, currentTask, type, asset }: data) => {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { isSharingScreen } = useVideoContext();
  const isRecording = useIsRecording();
  const previewTest = true;
  const height = useHeight();
  const { user } = useAppState();

  return (
    <>
      {type !== 'web' ? (
        <div>
          <div className={classes.container} style={{ height }}>
            <div className={classes.left}>
              <div className={classes.nonFlex}>
                Oppgave {currentTask} av {totalTasks}
                <p className={classes.instruction}>Les spørsmålet høyt og deretter besvar det:</p>
                <h1 className={classes.task}>{text}</h1>
              </div>
              {!previewTest ? (
                <>
                  {isSharingScreen && isRecording ? <Navigation /> : ''}

                  {!isSharingScreen && !isMobile && (
                    <div>
                      <p>Du må dele skjermen din: </p>
                      <ToggleScreenShareButton disabled={isReconnecting} />
                    </div>
                  )}

                  {isRecording ? '' : <ToggleRecordButton />}
                </>
              ) : (
                <Navigation />
              )}
            </div>

            <div className={classes.right}>
              {link && (
                <>
                  {type === 'android' ? (
                    <iframe
                      src={`https://appetize.io/embed/standalone_2k37f4arw312kvp75hge5z02uc?device=pixel6&scale=auto&autoplay=true&language=nb_NO&locale=nb_NO&launchUrl=${link}`}
                      className={classes.responsiveIframe}
                      frameBorder="0"
                      scrolling="no"
                      title="Android Mobile"
                    ></iframe>
                  ) : type === 'ios' ? (
                    <iframe
                      src={`https://appetize.io/embed/standalone_2k37f4arw312kvp75hge5z02uc?device=iphone14pro&scale=auto&autoplay=true&language=nb_NO&launchUrl=${link}`}
                      className={classes.responsiveIframe}
                      frameBorder="0"
                      scrolling="no"
                      title="iOS Mobile"
                    ></iframe>
                  ) : type === 'byod' ? (
                    user?.userPreferences?.device === 'android' ? (
                      <iframe
                        src={`https://appetize.io/embed/standalone_2k37f4arw312kvp75hge5z02uc?device=pixel6&scale=auto&autoplay=true&language=nb_NO&locale=nb_NO&launchUrl=${link}`}
                        className={classes.responsiveIframe}
                        frameBorder="0"
                        scrolling="no"
                        title="Android Device"
                      ></iframe>
                    ) : (
                      <iframe
                        src={`https://appetize.io/embed/standalone_2k37f4arw312kvp75hge5z02uc?device=iphone14pro&scale=auto&autoplay=true&language=nb_NO&launchUrl=${link}`}
                        className={classes.responsiveIframe}
                        frameBorder="0"
                        scrolling="no"
                        title="iPhone Device"
                      ></iframe>
                    )
                  ) : type === 'app' ? (
                    <iframe
                      src={`${link}`}
                      className={classes.responsiveIframe}
                      frameBorder="0"
                      scrolling="no"
                      title="Mobile"
                    ></iframe>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <>
            <div>
              Oppgave {currentTask} av {totalTasks}
            </div>
            <p className={classes.instruction}>Les spørsmålet høyt og deretter besvar det:</p>
            <h1 className={classes.task}>{text}</h1>

            {link && (
              <h3 className={classes.linkText}>
                Åpne linken:{' '}
                <a className={classes.link} rel="noreferrer" target="_blank" href={link}>
                  {' '}
                  Klikk her
                </a>
              </h3>
            )}

            {asset?.image && (
              <h3 className={classes.linkText}>
                <a
                  className={classes.link}
                  rel="noreferrer"
                  target="_blank"
                  href={`https://app.testflow.no/asset?assetId=${asset?._id}`}
                >
                  Klikk her
                </a>
              </h3>
            )}

            {!previewTest ? (
              <>
                {isSharingScreen && isRecording ? <Navigation /> : ''}

                {!isSharingScreen && !isMobile && (
                  <div>
                    <p>Du må dele skjermen din: </p>
                    <ToggleScreenShareButton disabled={isReconnecting} />
                  </div>
                )}

                {isRecording ? '' : <ToggleRecordButton />}
              </>
            ) : (
              <Navigation />
            )}
          </>
        </div>
      )}
    </>
  );
};

const FirstPage = ({ text }: data) => {
  const roomState = useRoomState();
  const classes = useStyles();

  const isReconnecting = roomState === 'reconnecting';
  const { isSharingScreen } = useVideoContext();

  return (
    <div>
      {isSharingScreen ? (
        <h1 className={classes.task}>Flott. Du deler nå skjerm og kan starte.</h1>
      ) : (
        <h1 className={classes.task}>{text}</h1>
      )}
      {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
      {isSharingScreen ? <Navigation /> : ''}
    </div>
  );
};

const SimplePage = ({ text }: data) => {
  const roomState = useRoomState();
  const classes = useStyles();
  const isReconnecting = roomState === 'reconnecting';
  const { isSharingScreen } = useVideoContext();

  return (
    <div>
      <p className={classes.instruction}>Les introduksjonen høyt:</p>
      <h1 className={classes.task}>{text}</h1>
      {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
      {isSharingScreen ? <Navigation /> : ''}
    </div>
  );
};

const IntroPage = ({ text }: data) => {
  const classes = useStyles();

  return (
    <div>
      <h1 className={classes.introText}>{text}</h1>
      <Navigation />
    </div>
  );
};

const SecondPage = ({ text }: data) => {
  const isRecording = useIsRecording();
  const { isSharingScreen } = useVideoContext();

  return (
    <div>
      <h1>{text}</h1>
      {isRecording ? '' : <ToggleRecordButton />}
      {isRecording && isSharingScreen ? <Navigation /> : ''}
    </div>
  );
};

const LastPage = ({ text, text2 }: data) => {
  const isRecording = useIsRecording();
  const classes = useStyles();

  return (
    <div>
      {isRecording ? (
        <div>
          <h1 className={classes.introText}>{text}</h1>
          <p className={classes.instructionWithMarginBottom}>
            Ved å trykke på “Stopp opptak” bekrefter du innsendelse av testopptaket
          </p>
          <div className={classes.flex}>
            <Navigation />
            <ToggleRecordButton />
          </div>
        </div>
      ) : (
        <div>
          <h1 className={classes.introText}>{text2}</h1>
        </div>
      )}
    </div>
  );
};

const Page6 = () => (
  <LastPage
    text="Du er ferdig!👏 Du kan nå si hadet og avslutte testen."
    text2="Takk så mye, testen vil nå sendes til godkjenning. Du kan nå forlate testrommet."
  />
);

const Page6Preview = () => <IntroPage text="Du er ferdig!👏 Du kan nå si hadet og avslutte testen." />;

const MySteps = () => {
  //const stepsState = useStepsState();

  const testCaseData = useTestCaseData(); // TODO: Refactor so we just use one hook instead of two

  //console.log('testCaseData', JSON.stringify(testCaseData));

  const { room } = useVideoContext();
  var fields = room!.name.split(' – '); // THIS IS JUST A HACK BECOUSE THE ROOMNAME CONTAINS THE TESTER NAME
  const firstName = fields[1];
  const intromessage = `Hei, jeg heter ${firstName}`;
  //const stepsState = useStepsState(); // Denne kan nå fjernes

  if (testCaseData && testCaseData.data) {
    if (testCaseData?.data?.status !== 'draft' && testCaseData?.data?.status !== 'review') {
      // Showing all steps, including intro steps
      return (
        <>
          {testCaseData?.steps ? (
            <Steps>
              <Step key={`page/1`} id={'1'}>
                {testCaseData?.data &&
                  (testCaseData?.data?.panelSelection ? (
                    <IntroPage text="Tenk høyt under hele testen. Vi kan ikke lese tankene dine! Det er derfor viktig at du sier det du tenker under hele opptaket." />
                  ) : (
                    <IntroPage text="Tenk høyt under hele testen. Vi kan ikke lese tankene dine! Det er derfor viktig at du sier det du tenker under hele opptaket." />
                  ))}
              </Step>
              <Step key={`page/2`} id={'2'}>
                <IntroPage text="Det forventes at du snakker tydelig og at det er rolig rundt deg. Pass på at du ikke blir forstyrret i løpet av denne tiden og lukk alt personlig på skjermen din." />
              </Step>
              <Step key={`page/3`} id={'3'}>
                <FirstPage text="Gjør deg klar til å starte. Start med å dele skjermen din. Om nettleseren spør om tillatelse for deling av skjerm må du klikke 'aksepter'. Pass på å dele hele skjermen din. Dersom du bruker to skjermer, så pass på å dele riktig skjerm." />
              </Step>
              <Step key={`page/4`} id={'4'}>
                <SecondPage text="Du kan nå starte opptaket og klikke neste" />
              </Step>
              <Step key={`page/5`} id={'5'}>
                <SimplePage text={intromessage} />
              </Step>

              {testCaseData?.steps[0]?.task && (
                <Step key={`page/6`} id={'6'}>
                  <DynamicPage
                    text={testCaseData?.steps[0]?.task}
                    link={testCaseData?.steps[0]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={1}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[0]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[1]?.task && (
                <Step key={`page/7`} id={'7'}>
                  <DynamicPage
                    text={testCaseData?.steps[1]?.task}
                    link={testCaseData?.steps[1]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={2}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[1]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[2]?.task && (
                <Step key={`page/8`} id={'8'}>
                  <DynamicPage
                    text={testCaseData?.steps[2]?.task}
                    link={testCaseData?.steps[2]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={3}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[2]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[3]?.task && (
                <Step key={`page/9`} id={'9'}>
                  <DynamicPage
                    text={testCaseData?.steps[3]?.task}
                    link={testCaseData?.steps[3]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={4}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[3]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[4]?.task && (
                <Step key={`page/10`} id={'10'}>
                  <DynamicPage
                    text={testCaseData?.steps[4]?.task}
                    link={testCaseData?.steps[4]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={5}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[4]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[5]?.task && (
                <Step key={`page/11`} id={'11'}>
                  <DynamicPage
                    text={testCaseData?.steps[5]?.task}
                    link={testCaseData?.steps[5]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={6}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[5]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[6]?.task && (
                <Step key={`page/12`} id={'12'}>
                  <DynamicPage
                    text={testCaseData?.steps[6]?.task}
                    link={testCaseData?.steps[6]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={7}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[6]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[7]?.task && (
                <Step key={`page/13`} id={'13'}>
                  <DynamicPage
                    text={testCaseData?.steps[7]?.task}
                    link={testCaseData?.steps[7]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={8}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[7]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[8]?.task && (
                <Step key={`page/14`} id={'14'}>
                  <DynamicPage
                    text={testCaseData?.steps[8]?.task}
                    link={testCaseData?.steps[8]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={9}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[8]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[9]?.task && (
                <Step key={`page/15`} id={'15'}>
                  <DynamicPage
                    text={testCaseData?.steps[9]?.task}
                    link={testCaseData?.steps[9]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={10}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[9]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[10]?.task && (
                <Step key={`page/16`} id={'16'}>
                  <DynamicPage
                    text={testCaseData?.steps[10]?.task}
                    link={testCaseData?.steps[10]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={11}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[10]?.stepAsset}
                  />
                </Step>
              )}
              {testCaseData?.steps[11]?.task && (
                <Step key={`page/17`} id={'17'}>
                  <DynamicPage
                    text={testCaseData?.steps[11]?.task}
                    link={testCaseData?.steps[11]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={12}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[12]?.task && (
                <Step key={`page/18`} id={'18'}>
                  <DynamicPage
                    text={testCaseData?.steps[12]?.task}
                    link={testCaseData?.steps[12]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={13}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[13]?.task && (
                <Step key={`page/19`} id={'19'}>
                  <DynamicPage
                    text={testCaseData?.steps[13]?.task}
                    link={testCaseData?.steps[13]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={14}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[14]?.task && (
                <Step key={`page/20`} id={'20'}>
                  <DynamicPage
                    text={testCaseData?.steps[14]?.task}
                    link={testCaseData?.steps[14]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={15}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[15]?.task && (
                <Step key={`page/21`} id={'21'}>
                  <DynamicPage
                    text={testCaseData?.steps[15]?.task}
                    link={testCaseData?.steps[15]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={16}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[16]?.task && (
                <Step key={`page/22`} id={'22'}>
                  <DynamicPage
                    text={testCaseData?.steps[16]?.task}
                    link={testCaseData?.steps[16]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={17}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[17]?.task && (
                <Step key={`page/23`} id={'23'}>
                  <DynamicPage
                    text={testCaseData?.steps[17]?.task}
                    link={testCaseData?.steps[17]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={18}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[18]?.task && (
                <Step key={`page/24`} id={'24'}>
                  <DynamicPage
                    text={testCaseData?.steps[18]?.task}
                    link={testCaseData?.steps[18]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={19}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[19]?.task && (
                <Step key={`page/25`} id={'25'}>
                  <DynamicPage
                    text={testCaseData?.steps[19]?.task}
                    link={testCaseData?.steps[19]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={20}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[20]?.task && (
                <Step key={`page/26`} id={'26'}>
                  <DynamicPage
                    text={testCaseData?.steps[20]?.task}
                    link={testCaseData?.steps[20]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={21}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[21]?.task && (
                <Step key={`page/27`} id={'27'}>
                  <DynamicPage
                    text={testCaseData?.steps[21]?.task}
                    link={testCaseData?.steps[21]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={22}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[22]?.task && (
                <Step key={`page/28`} id={'28'}>
                  <DynamicPage
                    text={testCaseData?.steps[22]?.task}
                    link={testCaseData?.steps[22]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={23}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[23]?.task && (
                <Step key={`page/29`} id={'29'}>
                  <DynamicPage
                    text={testCaseData?.steps[23]?.task}
                    link={testCaseData?.steps[23]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={24}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[24]?.task && (
                <Step key={`page/30`} id={'30'}>
                  <DynamicPage
                    text={testCaseData?.steps[24]?.task}
                    link={testCaseData?.steps[24]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={25}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[25]?.task && (
                <Step key={`page/31`} id={'31'}>
                  <DynamicPage
                    text={testCaseData?.steps[22]?.task}
                    link={testCaseData?.steps[22]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={26}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[26]?.task && (
                <Step key={`page/32`} id={'32'}>
                  <DynamicPage
                    text={testCaseData?.steps[23]?.task}
                    link={testCaseData?.steps[23]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={27}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[27]?.task && (
                <Step key={`page/33`} id={'33'}>
                  <DynamicPage
                    text={testCaseData?.steps[24]?.task}
                    link={testCaseData?.steps[24]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={28}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              {testCaseData?.steps[28]?.task && (
                <Step key={`page/34`} id={'34'}>
                  <DynamicPage
                    text={testCaseData?.steps[24]?.task}
                    link={testCaseData?.steps[24]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={29}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}
              <Step key={`page/35`} id={'35'}>
                <Page6 />
              </Step>
            </Steps>
          ) : null}
        </>
      );
    } else
      return (
        // Same steps, but removed the intro steps
        <>
          {testCaseData?.steps ? (
            <Steps>
              {testCaseData?.steps[0]?.task && (
                <Step key={`page/6`} id={'6'}>
                  <DynamicPage
                    text={testCaseData?.steps[0]?.task}
                    link={testCaseData?.steps[0]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={1}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[0]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[1]?.task && (
                <Step key={`page/7`} id={'7'}>
                  <DynamicPage
                    text={testCaseData?.steps[1]?.task}
                    link={testCaseData?.steps[1]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={2}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[1]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[2]?.task && (
                <Step key={`page/8`} id={'8'}>
                  <DynamicPage
                    text={testCaseData?.steps[2]?.task}
                    link={testCaseData?.steps[2]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={3}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[2]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[3]?.task && (
                <Step key={`page/9`} id={'9'}>
                  <DynamicPage
                    text={testCaseData?.steps[3]?.task}
                    link={testCaseData?.steps[3]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={4}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[3]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[4]?.task && (
                <Step key={`page/10`} id={'10'}>
                  <DynamicPage
                    text={testCaseData?.steps[4]?.task}
                    link={testCaseData?.steps[4]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={5}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[4]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[5]?.task && (
                <Step key={`page/11`} id={'11'}>
                  <DynamicPage
                    text={testCaseData?.steps[5]?.task}
                    link={testCaseData?.steps[5]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={6}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[5]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[6]?.task && (
                <Step key={`page/12`} id={'12'}>
                  <DynamicPage
                    text={testCaseData?.steps[6]?.task}
                    link={testCaseData?.steps[6]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={7}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[6]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[7]?.task && (
                <Step key={`page/13`} id={'13'}>
                  <DynamicPage
                    text={testCaseData?.steps[7]?.task}
                    link={testCaseData?.steps[7]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={8}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[7]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[8]?.task && (
                <Step key={`page/14`} id={'14'}>
                  <DynamicPage
                    text={testCaseData?.steps[8]?.task}
                    link={testCaseData?.steps[8]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={9}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[8]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[9]?.task && (
                <Step key={`page/15`} id={'15'}>
                  <DynamicPage
                    text={testCaseData?.steps[9]?.task}
                    link={testCaseData?.steps[9]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={10}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[9]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[10]?.task && (
                <Step key={`page/16`} id={'16'}>
                  <DynamicPage
                    text={testCaseData?.steps[10]?.task}
                    link={testCaseData?.steps[10]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={11}
                    type={testCaseData?.data?.type}
                    asset={testCaseData?.steps[10]?.stepAsset}
                  />
                </Step>
              )}

              {testCaseData?.steps[11]?.task && (
                <Step key={`page/17`} id={'17'}>
                  <DynamicPage
                    text={testCaseData?.steps[11]?.task}
                    link={testCaseData?.steps[11]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={12}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[12]?.task && (
                <Step key={`page/18`} id={'18'}>
                  <DynamicPage
                    text={testCaseData?.steps[12]?.task}
                    link={testCaseData?.steps[12]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={13}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[13]?.task && (
                <Step key={`page/19`} id={'19'}>
                  <DynamicPage
                    text={testCaseData?.steps[13]?.task}
                    link={testCaseData?.steps[13]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={14}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[14]?.task && (
                <Step key={`page/20`} id={'20'}>
                  <DynamicPage
                    text={testCaseData?.steps[14]?.task}
                    link={testCaseData?.steps[14]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={15}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[15]?.task && (
                <Step key={`page/21`} id={'21'}>
                  <DynamicPage
                    text={testCaseData?.steps[15]?.task}
                    link={testCaseData?.steps[15]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={16}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[16]?.task && (
                <Step key={`page/22`} id={'22'}>
                  <DynamicPage
                    text={testCaseData?.steps[16]?.task}
                    link={testCaseData?.steps[16]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={17}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[17]?.task && (
                <Step key={`page/23`} id={'23'}>
                  <DynamicPage
                    text={testCaseData?.steps[17]?.task}
                    link={testCaseData?.steps[17]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={18}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[18]?.task && (
                <Step key={`page/24`} id={'24'}>
                  <DynamicPage
                    text={testCaseData?.steps[18]?.task}
                    link={testCaseData?.steps[18]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={19}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[19]?.task && (
                <Step key={`page/25`} id={'25'}>
                  <DynamicPage
                    text={testCaseData?.steps[19]?.task}
                    link={testCaseData?.steps[19]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={20}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[20]?.task && (
                <Step key={`page/26`} id={'26'}>
                  <DynamicPage
                    text={testCaseData?.steps[20]?.task}
                    link={testCaseData?.steps[20]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={21}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[21]?.task && (
                <Step key={`page/27`} id={'27'}>
                  <DynamicPage
                    text={testCaseData?.steps[21]?.task}
                    link={testCaseData?.steps[21]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={22}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[22]?.task && (
                <Step key={`page/28`} id={'28'}>
                  <DynamicPage
                    text={testCaseData?.steps[22]?.task}
                    link={testCaseData?.steps[22]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={23}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[23]?.task && (
                <Step key={`page/29`} id={'29'}>
                  <DynamicPage
                    text={testCaseData?.steps[23]?.task}
                    link={testCaseData?.steps[23]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={24}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[24]?.task && (
                <Step key={`page/30`} id={'30'}>
                  <DynamicPage
                    text={testCaseData?.steps[24]?.task}
                    link={testCaseData?.steps[24]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={25}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[25]?.task && (
                <Step key={`page/31`} id={'31'}>
                  <DynamicPage
                    text={testCaseData?.steps[22]?.task}
                    link={testCaseData?.steps[22]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={26}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[26]?.task && (
                <Step key={`page/32`} id={'32'}>
                  <DynamicPage
                    text={testCaseData?.steps[23]?.task}
                    link={testCaseData?.steps[23]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={27}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[27]?.task && (
                <Step key={`page/33`} id={'33'}>
                  <DynamicPage
                    text={testCaseData?.steps[24]?.task}
                    link={testCaseData?.steps[24]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={28}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              {testCaseData?.steps[28]?.task && (
                <Step key={`page/34`} id={'34'}>
                  <DynamicPage
                    text={testCaseData?.steps[24]?.task}
                    link={testCaseData?.steps[24]?.link}
                    totalTasks={testCaseData?.steps?.length}
                    currentTask={29}
                    type={testCaseData?.data?.type}
                  />
                </Step>
              )}

              <Step key={`page/35`} id={'35'}>
                <Page6Preview />
              </Step>
            </Steps>
          ) : null}
        </>
      );
  } else {
    return <></>;
  }
};

export default function TaskSteps() {
  return (
    <WizardProvider>
      <MySteps />
    </WizardProvider>
  );
}
