import { LocalVideoTrack, RemoteVideoTrack, TwilioError } from 'twilio-video';

declare module 'twilio-video' {
  // These help to create union types between Local and Remote VideoTracks
  interface LocalVideoTrack {
    isSwitchedOff: undefined;
    setPriority: undefined;
  }
}

declare global {
  interface Window {
    visualViewport?: {
      scale: number;
    };
  }

  interface MediaDevices {
    getDisplayMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
  }

  interface HTMLMediaElement {
    setSinkId?(sinkId: string): Promise<undefined>;
  }

  // Helps create a union type with TwilioError
  interface Error {
    code: undefined;
  }
}

export type Callback = (...args: any[]) => void;

export type ErrorCallback = (error: TwilioError | Error) => void;

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export type RoomType = 'group' | 'group-small' | 'peer-to-peer' | 'go';

export interface Meta {
  type: string;
  origin: string;
}

export interface UserPreferences {
  language: string;
  device: string;
  redirectionSuccess?: string;
}

export interface User {
  displayName?: string;
  userPreferences?: UserPreferences;
  meta: Meta;
  photoURL?: string;
  passcode: string;
}

// Default values for User
export const defaultUser: User = {
  displayName: undefined,
  userPreferences: {
    language: 'nb-NO',
    device: 'ios',
  },
  meta: {
    type: '',
    origin: '',
  },
  photoURL: undefined,
  passcode: '',
};

export type CaseData = {
  id: string;
  title?: string;
};

export type TesterData = {
  id: string;
  name?: string;
};

export type TesterId = {
  id: string;
};

export type RecordingRule = {
  type: 'include' | 'exclude';
  all?: boolean;
  kind?: 'audio' | 'video';
  publisher?: string;
};

export type RecordingRules = RecordingRule[];
