import React from 'react';
import AvatarIcon from '../../../../icons/AvatarIcon';
import { makeStyles, Theme } from '@material-ui/core';
//import { makeStyles, Theme, Typography } from '@material-ui/core';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'block',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  videoContainer: {
    //position: 'relative',
    flexGrow: 1,
    height: 0,
    overflow: 'hidden',
    paddingTop: `${(9 / 16) * 100}%`,
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  audioContainer: {},
  audio: {
    color: 'black',
    padding: '0.18em 0.5em',
    margin: 0,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 14,
    fontSize: '1.1em',
    height: '100%',
  },
  avatarContainer: {
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    borderRadius: 12,
    top: 0,
    right: 0,
    bottom: 10,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
  description: {
    color: 'darkgrey',
    textAlign: 'center',
  },
}));

export default function LocalVideoPreview({ identity }: { identity: string }) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  return (
    <div className={classes.container}>
      <div className={classes.videoContainer}>
        <div className={classes.innerContainer}>
          {videoTrack ? (
            <VideoTrack track={videoTrack} isLocal />
          ) : (
            <div className={classes.avatarContainer}>
              <div className={classes.description}>
                <AvatarIcon />
                <p>
                  <strong>Mangler video</strong> <br />
                  Sjekk tilgang for kamera
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={classes.audioContainer}>
        <div className={classes.audio}>
          <LocalAudioLevelIndicator />

          {/*
          //<Typography variant="body1" color="inherit" component="span">
          // Visningsnavn: {identity}
          //</Typography>
          //*/}
        </div>
      </div>
    </div>
  );
}
