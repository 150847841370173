// src/components/SessionTimeoutDialog/SessionTimeoutDialog.tsx

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Divider,
  DialogActions,
  Button,
} from '@material-ui/core';

interface SessionTimeoutDialogProps {
  open: boolean;
  onClose: () => void;
  onExtend: () => void;
  sessionExtended: boolean;
}

export default function SessionTimeoutDialog({ open, onClose, onExtend, sessionExtended }: SessionTimeoutDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Øktvarsel</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {sessionExtended
            ? "Din økt har blitt forlenget. Klikk 'Fortsett' for å lukke denne meldingen"
            : "Økten din er i ferd med å utløpe på grunn av inaktivitet. Klikk 'Forleng økt' for å forbli tilkoblet."}
        </DialogContentText>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {sessionExtended ? 'Fortsett' : 'Lukk'}
        </Button>
        {!sessionExtended && (
          <Button onClick={onExtend} color="primary" variant="contained">
            Forleng økt
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
