import React, { createContext, ReactNode } from 'react';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: any[];
  conversation: any | null;
};

// Create the context with default values
export const ChatContext = createContext<ChatContextType>({
  isChatWindowOpen: false,
  setIsChatWindowOpen: () => {},
  connect: () => {},
  hasUnreadMessages: false,
  messages: [],
  conversation: null,
});

// Define the props interface for ChatProvider
interface ChatProviderProps {
  children: ReactNode; // Allow any valid React nodes
}

// Update the ChatProvider component to accept children
export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen: false,
        setIsChatWindowOpen: () => {},
        connect: () => {},
        hasUnreadMessages: false,
        messages: [],
        conversation: null,
      }}
    >
      {children} {/* Render the children here */}
    </ChatContext.Provider>
  );
};
