import { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import sanityClient from '../../sanity';
import { nanoid } from 'nanoid';
import { User, defaultUser } from '../../types';
import { useApiGet } from '../../hooks/useApiGet/useApiHook';

const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

export function getPasscode() {
  const match = window.location.search.match(/passcode=(.*)&?/);
  const passcode = match ? match[1] : window.sessionStorage.getItem('passcode');
  return passcode;
}

export function fetchToken(
  name: string,
  room: string,
  passcode: string,
  create_room = true,
  create_conversation = process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true'
) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      identity: name,
      room: room,
      user_identity: name,
      room_name: room,
      passcode,
      create_room,
      create_conversation,
    }),
  });
}

export function verifyPasscode(passcode: string) {
  return fetchToken('temp-name', 'temp-room', passcode, false, false).then(async res => {
    const jsonResponse = await res.json();
    if (res.status === 401) {
      return { isValid: false, error: jsonResponse.error?.message };
    }

    if (res.ok && jsonResponse.token) {
      return { isValid: true };
    }
  });
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const history = useHistory();

  const userDataEndpoint = process.env.REACT_APP_GET_USERDATA || 'missing endpoint';
  const [user, setUser] = useState<User | null>(defaultUser);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(
    (name: string, room: string) => {
      return fetchToken(name, room, user!.passcode)
        .then(async res => {
          if (res.ok) {
            return res;
          }
          const json = await res.json();
          const errorMessage = getErrorMessage(json.error?.message || res.statusText);
          throw Error(errorMessage);
        })
        .then(res => res.json());
    },
    [user]
  );

  const updateRecordingRules = useCallback(
    async (room_sid: string, rules: any) => {
      // Explicitly type parameters
      const endpointRecording = process.env.REACT_APP_RECORDING_ENDPOINT || '/recordingrules';

      return fetch(endpointRecording, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ room_sid, rules, passcode: user?.passcode }),
        method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error updating recording rules');
          error.code = jsonResponse.error?.code;
          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  const passcode = getPasscode();

  const userId = useMemo(() => {
    if (passcode) {
      const fields = passcode.split('-');
      return fields[1];
    }
    return null;
  }, [passcode]);

  const { data: apiData } = useApiGet(userId ? `${userDataEndpoint}${userId}` : '');

  useEffect(() => {
    if (!passcode) {
      setIsAuthReady(true);
      return;
    }

    verifyPasscode(passcode)
      .then(verification => {
        if (verification?.isValid) {
          if (apiData) {
            const userdata = apiData.body.data;

            const userData: User = {
              displayName: userdata.firstName || 'Unknown',
              userPreferences: {
                device: userdata.userDevice || 'ios',
                language: userdata.userLanguage || 'nb-NO',
              },
              meta: {
                type: '',
                origin: '',
              },
              photoURL: undefined,
              passcode: passcode,
            };
            setUser(userData);

            window.sessionStorage.setItem('passcode', passcode);
            history.replace(window.location.pathname);
          }
        }
      })
      .then(() => setIsAuthReady(true));
  }, [history, passcode, apiData]);

  const getTestCaseData = useCallback(async (slug: string) => {
    // Explicitly type parameter
    const baseEndpoint = process.env.REACT_APP_GET_TESTCASE_ENDPOINT;
    const testcaseEndpoint = baseEndpoint ? `${baseEndpoint}${slug}` : 'missing endpoint';

    return fetch(testcaseEndpoint, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify({ slug }),
      method: 'POST',
    }).then(async res => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(jsonResponse.error?.message || 'There was an error getting testcase data');
        error.code = jsonResponse.error?.code;
        return Promise.reject(error);
      }
      return jsonResponse;
    });
  }, []);

  const startTest = useCallback(async (room_name: string, room_sid: string) => {
    // Explicitly type parameters
    var fields = room_name.split(' – ');

    if (fields[1] !== 'Anonym Tester') {
      try {
        await sanityClient.createIfNotExists({
          _id: room_sid,
          _type: 'testResult',
          testerName: fields[1],
          testCaseSlug: fields[0],
          roomId: room_sid,
          status: 'new',
          testerEmail: fields[4],
          testerId: fields[2],
          source: fields[3],
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  const endTest = useCallback(
    async (room_name: string, room_sid: string, case_id: string, redirectionSuccess: string) => {
      // Explicitly type parameters
      var fields = room_name.split(' – ');

      if (fields[1] !== 'Anonym Tester') {
        try {
          await sanityClient
            .patch(room_sid)
            .set({ status: 'recordingComplete' })
            .commit();
          if (fields[3] === 'norstat') {
            if (isValidUrl(redirectionSuccess)) {
              window.location.href = redirectionSuccess;
            } else {
              window.location.href = 'https://webs.norstatsurveys.com/z/Complete';
            }
          }
        } catch (err) {
          console.error(err);
        }
        try {
          await sanityClient
            .patch(case_id)
            .setIfMissing({ testResults: [] })
            .append('testResults', [{ _key: nanoid(), _ref: room_sid, _type: 'reference' }])
            .commit();
          if (fields[3] === 'norstat') {
            if (isValidUrl(redirectionSuccess)) {
              window.location.href = redirectionSuccess;
            } else {
              window.location.href = 'https://webs.norstatsurveys.com/z/Complete';
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    []
  );

  const signIn = useCallback((inputPasscode: string) => {
    return verifyPasscode(inputPasscode).then(verification => {
      if (verification?.isValid) {
        setUser({ inputPasscode } as any);
        window.sessionStorage.setItem('passcode', inputPasscode);
      } else {
        throw new Error(getErrorMessage(verification?.error));
      }
    });
  }, []);

  function isValidUrl(urlString: string): boolean {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  }

  const signOut = useCallback((redirectionSuccess: string) => {
    // Explicitly type parameter
    setUser(null);
    window.sessionStorage.removeItem('passcode');

    if (redirectionSuccess && isValidUrl(redirectionSuccess)) {
      window.location.href = redirectionSuccess;
    }

    return Promise.resolve();
  }, []);

  return { user, isAuthReady, getToken, signIn, signOut, updateRecordingRules, endTest, startTest, getTestCaseData };
}
