import { useState, useEffect } from 'react';
import { SELECTED_BACKGROUND_SETTINGS_KEY } from '../../../constants';

export interface BackgroundSettings {
  type: string; // You can specify a more detailed type if needed
  index?: number;
}

const imageNames: string[] = [
  'Abstract',
  'Boho Home',
  'Bookshelf',
  'Coffee Shop',
  'Contemporary',
  'Cozy Home',
  'Desert',
  'Fishing',
  'Flower',
  'Kitchen',
  'Modern Home',
  'Nature',
  'Ocean',
  'Patio',
  'Plant',
  'San Francisco',
];

// Mock images array (if needed)
const images: string[] = []; // Placeholder if you want to use this later

// Mock configuration
export const backgroundConfig = {
  imageNames,
  images,
};

// Mock implementation without video processing
export default function useBackgroundSettings() {
  const [backgroundSettings, setBackgroundSettings] = useState<BackgroundSettings>(() => {
    const localStorageSettings = window.localStorage.getItem(SELECTED_BACKGROUND_SETTINGS_KEY);
    return localStorageSettings ? JSON.parse(localStorageSettings) : { type: 'none', index: 0 };
  });

  // Effect runs but doesn't do any processing
  useEffect(() => {
    // Save background settings without any processing logic
    window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify(backgroundSettings));
  }, [backgroundSettings]);

  return [backgroundSettings, setBackgroundSettings] as const; // Return the state and setter
}
