import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

function Dialog7({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Vilkår for testere</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <p>
            Disse vilkårene beskriver avtalen mellom deg som utførre tester (heretter kalt “tester”, “deg”) og Testflow
            AS (heretter kalt “Testflow”, “oss” eller “vi”). Vilkårene beskriver hvilke rettigheter og plikter som
            gjelder mellom deg og Testflow. Ved å gjennomføre og sende inn en test godtar du vilkårene for bruk og det
            blir lagret data om deg som tester.
          </p>

          <h3>1. Hvem kan være tester</h3>
          <p>
            1.1 Du må være over 18 år for å være tester for Testflow. Er du under 18 år, trenger vi skriftlig
            godkjenning fra foreldre eller foresatte.
          </p>
          <p>1.2 Du må bo i Norge og være registrert med en adresse i Norge.</p>

          <h3>2. Registrering</h3>
          <p>
            2.1 For å bli en tester må du registrere deg på nettstedet vårt og oppgi personlig informasjon. Du vil da få
            en konto hos Testflow og bli en del av Testflowpanelet.
          </p>
          <p>
            2.2 Fullt navn, e-post og mobiltelefon trenger vi i Testflow for å vite hvem du er, samt til å invitere deg
            til tester og kontakte deg ved behov.
          </p>
          <p>
            2.3 Alder og kjønn blir registrert for å å kunne sende tester som passer til din demografi. Det godtar
            innsamling og bruk av slik personlig identifiserbar informasjon.
          </p>

          <h3>3. Opptak og bruk av opptak</h3>
          <p>
            3.1 Du forstår og godtar at hver test du tar er tatt opp i et opptak og at opptaket inkluderer video fra
            ditt webkamera, lydopptak av stemmen din og opptak av skjermen din.
          </p>
          <p>
            3.2 Du forstår og godtar videre at Testflow vil gi en kopi av opptaket til kunden som har opprettet testen,
            og at opptaket kan sees og brukes av kunde til sitt arbeid og vises internt i sin organisasjon. Testflow vil
            også kunne se videoene internt for arbeid i forbindelse med kvalitetssikring.
          </p>
          <p>
            3.3 Alle opptak behandles konfidensielt, og Testflow sørger for at opptak kun deles med den aktuelle kunden
            og ikke offentliggjøres eller distribueres uten samtykke fra testdeltakeren. Testflow har strenge rutiner
            for å sikre at ingen sensitiv informasjon blir delt under opptakene.
          </p>

          <h3>4. Taushetsplikt</h3>
          <p>
            4.1 Du godtar taushetsplikt av alt du tester på Testflow, det vil si at du ikke skal fortelle eller dele
            informasjon du tilegner deg med andre. Dette inkluderer innhold i testen, informasjon om kunden og
            informasjon om hva som testes. Som tester vil du kunne teste prototyper, konsepter og tjenester som ikke er
            tilgjengelig på markedet enda.
          </p>
          <p>4.2 Du kan ikke kopiere eller ta skjermbilder/videoer av testene.</p>
          <p>
            4.3 Hvis tjenestene for brukertesting gir deg tilgang til et passordbeskyttet nettsted eller et
            ikke-offentlig nettsted, skal du ikke bruke dette etter testen er fullført.
          </p>

          <h3>5. Brukertesting</h3>
          <h4>5.1 Før du starter en test</h4>
          <ul>
            <li>Ha et fungerende webkamera og mikrofon med god bredbånd-tilkobling.</li>
            <li>Sørg for at det ikke er forstyrrelser rundt deg under opptak av testen.</li>
            <li>Sørg for å lese og følge alle oppgaver eller spørsmål nøye og rolig.</li>
            <li>
              Når du svarer på spørsmål eller følger instruksjoner, prøv å ikke avvike for langt fra
              spørsmålet/oppgaven.
            </li>
            <li>Snakk tydelig og kommenter høyt og del tankene dine under brukeropplevelsen gjennom testen.</li>
            <li>
              Du skal ikke dele personlig informasjon som fullt navn, adresse, fødselsdato eller annen sensitiv
              informasjon under gjennomføringen av testen.
            </li>
            <li>
              Hvis du ikke overholder disse kravene, kan innsendingen din bli avvist og du vil ikke bli betalt for
              innsatsen din.
            </li>
          </ul>

          <h3>5.2 Gjennomføring av testen</h3>
          <p>
            Du godtar å bli varslet via e-post og SMS når det er en test tilgjengelig for deg å ta. En test kan ha en
            varighet på 5, 10 eller 20 minutter. Hold deg så langt det lar seg gjøre, i nærheten av lengden på testen.
            Ved gjennomføring av testen må du sørge for at du er alene, ikke har noen sensitive data synlige på skjermen
            du deler, og ikke under noen omstendigheter deler fullt navn på tredjeparter eller annen sensitiv personlig
            informasjon.
          </p>

          <h3>5.3 Testdeltakernes personvern</h3>
          <p>
            Testflow tar ansvar for å gi deltakerne klare instruksjoner om hvordan de kan beskytte deres personlige
            informasjon under testingen. Testopptak skal kun brukes til formålene spesifisert av kunden og Testflow, og
            alle opptak behandles i samsvar med gjeldende personvernlover.
          </p>

          <h3>6. Godtgjørelse</h3>
          <h4>6.1 Godtgjørelse for godkjente tester</h4>
          <p>
            Vi utbetaler godtgjørelse for tester som er godkjent en gang hver måned. Du må oppgi et norsk
            bankkontonummer i ditt navn for at vi skal kunne overføre honorar.
          </p>

          <h3>6.2 Krav til godkjent test</h3>
          <ul>
            <li>Vi kan se tydelig video av deg som tester.</li>
            <li>Vi hører en tydelig stemme uten for mye støy i bakgrunnen.</li>
            <li>Du snakker høyt under testen.</li>
            <li>Vi ser skjermdeling som viser Testflow og eventuelle linker som åpnes.</li>
            <li>Du svarer på spørsmålene du blir stilt og utfører oppgavene du får.</li>
            <li>Du holder deg innen rimelig tidslengde basert på testtype (5 min, 10 min eller 20 min).</li>
          </ul>

          <h3>7. Konkurranse</h3>
          <p>
            Du samtykker i å ikke kontakte kunder som har kjøpt testtjenester gjennom nettstedet vårt eller gjennom
            informasjon hentet fra nettstedet vårt med den hensikt å tilby dine egne tjenester direkte til kunden som en
            erstatning for våre tjenester.
          </p>

          <h3>8. Avslutte avtale/konto</h3>
          <p>
            Dersom du ikke lenger ønsker å være tester for oss eller om du ønsker at din konto avsluttes, må du ta
            kontakt med oss og be oss avslutte din konto ved å skrive en e-post fra den e-posten du er registrert med
            til hei@testflow.no.
          </p>

          <h3>9. Kontakt oss</h3>
          <p>
            For spørsmål angående disse vilkårene, vennligst kontakt hei@testflow.no eller vår chat på nettsiden.
            <br />
            Hilsen Testflow
          </p>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialog7;
