import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

//import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
//import { isMobile } from '../../utils';
//import Menu from './Menu/Menu';
//import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid } from '@material-ui/core';
//import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
//import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
//import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
//import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color: theme.brandSecondaryLight,
    },
    container: {
      backgroundColor: theme.brand,

      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    micInfoBanner: {
      position: 'fixed',
      zIndex: 20,
      opacity: 0.9,
      bottom: 200,
      left: 0,
      right: 0,
      height: '84px',
      background: 'lightYellow',

      '& h6': {
        color: `${theme.brand}`,
        fontSize: '14px',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        borderRadius: '25px',
        border: `2px solid ${theme.brand}`,
        fontSize: '14px',
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 20,
      opacity: 0.1,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '84px',
      //background: `${theme.brand}`,
      '& h6': {
        color: 'white',
        fontSize: '14px',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        borderRadius: '25px',
        border: `2px solid ${theme.brand}`,
        fontSize: '14px',
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  //const { isSharingScreen, toggleScreenShare } = useVideoContext();
  //const [isAudioEnabled] = useLocalAudioToggle();

  //const roomState = useRoomState();
  //const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();

  var fields = room!.name.split(' – '); // THIS IS JUST A HACK BECOUSE THE ROOMNAME CONTAINS THE TESTER ID
  const roomnameShortVersion = fields[0] + ' - ' + fields[1];

  return (
    <>
      {/* COMMENT THIS OUT FOR NOW
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">Du deler nå skjermen din</Typography>
          <Button onClick={() => toggleScreenShare()}>Stopp del skjerm</Button>
        </Grid>
      )}
      */}

      {/* COMMENT THIS OUT FOR NOW
      {!isAudioEnabled && (
        <Grid container justifyContent="center" alignItems="center" className={classes.micInfoBanner}>
          <Typography variant="h6">Du har ikke mikrofon på, skru på mikrofon for å fortsette</Typography>
          <ToggleAudioButton disabled={isReconnecting} />
        </Grid>
      )}
      */}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid style={{ flex: 1 }}>
            <Typography variant="body1">
              <span className={classes.textColor}>{roomnameShortVersion}</span>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              {/* COMMENT THIS OUT FOR NOW
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              */}

              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
              {/* COMMENT THIS OUT FOR NOW
              <Hidden smDown>
                <Menu />
              </Hidden>
               */}
            </Grid>
          </Grid>
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-end">
              <EndCallButton />
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
